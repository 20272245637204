var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"__main_card_container"},[_c('div',{staticClass:"_main_card_header"},[_c('div',{staticClass:"__header_inner admin_header_padding"},[_c('h2',[(_vm.$route.meta.header == 'Industry Users')?_c('button',{staticClass:"back__btn",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('img',{attrs:{"width":"12px","height":"15px","src":require("@/assets/Arrow - Right.svg"),"alt":""}}),_vm._v(" Back ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$route.meta.header)+" ")]),_c('div',{staticClass:"side_nav_wrapper"},[_c('div',{staticClass:"__side_nav"},[(!_vm.disallowedUsers)?_c('button',{staticClass:"btn",class:[
              _vm.$route.path.includes('industries') ? '__side_item_active' : '',
            ],on:{"click":function($event){_vm.$router
                .replace('/admin/reporting/industries')
                .catch((err) => {})}}},[_vm._v(" Industries ")]):_vm._e(),(!_vm.disallowedUsers)?_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/reporting/sender_names'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router
                .replace('/admin/reporting/sender_names')
                .catch((err) => {})}}},[_vm._v(" Sender Names ")]):_vm._e(),_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/reporting/message_type'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router
                .replace('/admin/reporting/message_type')
                .catch((err) => {})}}},[_vm._v(" Message Type ")]),_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/reporting/message_summary'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router
                .replace('/admin/reporting/message_summary')
                .catch((err) => {})}}},[_vm._v(" Message Summary ")]),(!_vm.disallowedUsers)?_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/reporting/sales&market_BI'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router
                .replace('/admin/reporting/sales&market_BI')
                .catch((err) => {})}}},[_vm._v(" Sales and Market BI ")]):_vm._e(),_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/reporting/business_chasis'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router
                .replace('/admin/reporting/business_chasis')
                .catch((err) => {})}}},[_vm._v(" Business Chassis ")]),(!_vm.disallowedUsers)?_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/reporting/theo-bot'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router.replace('/admin/reporting/theo-bot').catch((err) => {})}}},[_vm._v(" TheoBot Report ")]):_vm._e()])])])]),_c('div',{staticClass:"main_card_body"},[_c('router-view')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }